import firebaseConfig from 'configs/FirebaseConfig';
import { initializeApp } from "firebase/app";
import {
	createUserWithEmailAndPassword,
	FacebookAuthProvider,
	getAuth,
	GoogleAuthProvider,
	signInWithEmailAndPassword,
	signInWithPopup,
	signOut
} from 'firebase/auth';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getFirestore } from 'firebase/firestore/lite';

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const currentUser = auth.currentUser
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();

export {
	auth, createUserWithEmailAndPassword, currentUser, db, facebookAuthProvider,
	FacebookAuthProvider, googleAuthProvider,
	GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, signOut
};

