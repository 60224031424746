import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import React from 'react'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.master-wallets',
        path: `${APP_PREFIX_PATH}/dashboards/master-wallets`,
        component: React.lazy(() => import('views/app-views/dashboards/master-wallets')),
    }, {
        key: 'dashboard.analytic',
        path: `${APP_PREFIX_PATH}/dashboards/analytic`,
        component: React.lazy(() => import('views/app-views/dashboards/analytic')),
    },
    {
        key: 'dashboard.user-list',
        path: `${APP_PREFIX_PATH}/dashboards/user-list`,
        component: React.lazy(() => import('views/app-views/dashboards/user-list')),
    },
    {
        key: 'dashboard.exchange',
        path: `${APP_PREFIX_PATH}/dashboards/exchange`,
        component: React.lazy(() => import('views/app-views/dashboards/exchange')),
    },
    {
        key: 'dashboard.withdrawal',
        path: `${APP_PREFIX_PATH}/dashboards/withdrawal`,
        component: React.lazy(() => import('views/app-views/dashboards/withdrawal')),
    },
    {
        key: 'dashboard.detail-user-list',
        path: `${APP_PREFIX_PATH}/dashboards/commision-records`,
        component: React.lazy(() => import('views/app-views/dashboards/commision-records')),
    },
    {
        key: 'dashboard.cs',
        path: `${APP_PREFIX_PATH}/dashboards/cs`,
        component: React.lazy(() => import('views/app-views/dashboards/cs')),
    },
    {
        key: 'apps.ecommerce.edit-product',
        path: `${APP_PREFIX_PATH}/dashboards/user-list/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/user-list/user')),
    },
]