

const dev = {
  API_ENDPOINT_URL: `http://tetherguy-development-backend-server.eba-uq44b25x.ap-northeast-2.elasticbeanstalk.com/v1`
};

const prod = {
  API_ENDPOINT_URL: `https://api.tetherguy.com/v1`
};

const staging = {
  API_ENDPOINT_URL: `https://api.dev-ftrsm.com/v1`
};

const getEnv = () => {
	switch (process.env.REACT_APP_STATUS_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'staging':
			return staging
		default:
			break;
	}
}


export const env = getEnv()