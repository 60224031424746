import { ERROR_RESPONSES } from 'constants/ApiConstant';
import { ACCESS_TOKEN } from 'constants/AuthConstant';

import { message } from 'antd';
import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';

import store from '../store';

const UNAUTHORIZED_CODE = [401, 403]
const BAD_REQUEST_CODE = [400,,404, 409]

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
})

// Config
const TOKEN_PAYLOAD_KEY = 'Authorization'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = `Bearer ${localStorage.getItem(ACCESS_TOKEN) || null}`;
	
	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
	}

  	return config
}, error => {
	// Do something with request error here
	message.error('임시 에러 처리한 부분')
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return response.data
}, (error) => {

	let notificationParam = {
		message: ''
	}

	// Remove token and redirect 
	if (UNAUTHORIZED_CODE.includes(error.response.status)) {
		notificationParam.message = ERROR_RESPONSES.accessExpired
		notificationParam.description = '로그인을 다시 해주세요'
		localStorage.removeItem(ACCESS_TOKEN)

		store.dispatch(signOutSuccess())
	}

	if (BAD_REQUEST_CODE.includes(error.response.status)) {
		if(error.response.status === 404) {
			notificationParam.message = error.response.data.message}
		else {notificationParam.message = '존재하지 않은 API URL이네요'}
		if(error.response.status === 400) {
			notificationParam.message = error.response.data.message
		}
		else notificationParam.message = '잘못된 요청이네요'
		
	}

	if (error.response.status === 500) {
		notificationParam.message = ERROR_RESPONSES[500]
	}
	
	if (error.response.status === 508) {
		notificationParam.message = ERROR_RESPONSES.networkError
	}

	message.error(notificationParam.message)

	return Promise.reject(error);
});

export default service