import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { THEME_CONFIG } from './configs/AppConfig';
import history from './history';
import './lang';
import Layouts from './layouts';
import store from './store';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <BrowserRouter history={history}>
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme={THEME_CONFIG.currentTheme}
              insertionPoint="styles-insertion-point"
            >
              <Layouts />
              <ReactQueryDevtools initialIsOpen={false} />
            </ThemeSwitcherProvider>
          </BrowserRouter>
        </Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
